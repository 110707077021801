<template>
    <div class="row enroll-section text-style-bold">
    <div class="col-md-8 col-sm-12">
        <div class="row" >
            <div class="col-md-12 vertical-spacing-100 hidden-sm "></div>
            <div class="col-md-12 vertical-spacing-25 hidden-md hidden-lg"></div>
            <div class="col-md-2"></div>
            <div class="col-md-8 text-center">
                <h1>Become a student!</h1>
                <!-- <h1> See pricing and availability here.</h1> -->
            </div>
            <div class="col-md-12 col-sm-12 vertical-spacing-25"></div>
            <div class="col-md-12 col-sm-12 enroll-button text-center link-no-style"> <router-link to="/sessions"><Button title='Details'/></router-link> </div>
            <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
        </div>
    </div>
    <div class="col-md-4 enroll-img hidden-sm"> </div>
    <div class="col-sm-12 hidden-md hidden-lg text-center">
        <img src="@/assets/sunset.jpg" alt="" class="image-small-devices" >
    </div>
    </div>
</template>

<script>
import Button from '@/components/Button.vue'

export default {
    components:{Button}

}
</script>

<style scoped>

.enroll-section{
    background-color: #f5daf1;
}
.enroll-img{
    background-image: url('../assets/sunset.jpg');
    background-color: rgba(31,32,38,0.1);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center bottom;
    height: 60vh;
}
.image-small-devices{
    height: 47vh;
}

.enroll-button{
    color:white;
}
</style>