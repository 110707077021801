<template>
    <div class="row contact-section text-style">
        <div class="col-md-9 col-sm-12 col-md-offset-1 col-lg-offset-1">
            <div class="row">
                <div class="col-md-12 vertical-spacing-50 hidden-sm"></div>
                <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
                <div class="col-md-4 col-sm-12 text-center">
                    <img src="../assets/social_icons/email.svg" alt="" class="email-image">
                    <p>rachael.daringenglish@gmail.com</p>
                </div>
                <div class="col-md-4 col-sm-12 text-center link-no-style">
                    <a href="https://www.tiktok.com/@daring_english" target="_blank">
                    <img src="../assets/social_icons/tiktok.svg" alt="" class="social-image">
                    <p>@daring_english</p>
                    </a>
                </div>
                <div class="col-md-4 col-sm-12 text-center link-no-style">
                    <a href="https://www.youtube.com/channel/UCHSHPqr0k6RyBy4Dcdb2bMQ/featured" target="_blank">
                        <img src="../assets/social_icons/youtube.svg" alt="" class="social-image">
                        <p>daring_english</p>
                    </a>
                </div>
                <div class="col-md-12 vertical-spacing-50 hidden-sm"></div>
                <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.contact-section{
    background-color: #f5daf1;
}
.email-image{
    height: 90px;
}
.social-image{
    height: 80px;
}


</style>