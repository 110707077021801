<template>
    <div class="app-buttons">
        <span> {{title}}</span>
    </div>
</template>

<script>
import { ref } from "vue"

export default {

props:['title'],
setup(props){
    
}

}
</script>

<style scoped>


.app-buttons{
    align-items: center;
    background-color:var(--backg);
    border-radius: 20px;
    box-shadow: 0 0 1px rgb(0 0 0 / 15%), 0 1px 2px rgb(0 0 0 / 5%);
    box-sizing: border-box;
    display: inline-flex;
    font-size: 1.5rem;
    font-weight: 700;
    height: 80px;
    transition: transform .3s;
    padding: 15px;
    margin: 10px;
    cursor: pointer;
}
.app-buttons:hover{
  transition: all .25s ease-in-out;
  transform: scale(1.025);
  
}
.app-buttons img{
  padding: 10px;
}
</style>