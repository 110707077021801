<template>
  <div class="row package-section">
      <div class="col-md-12 package-image"> 
        <img :src="require('@/assets/' + datail.img + '')" alt="">
      </div>
      <div class="col-md-12">
        <h1>{{datail.name}}</h1>
        <h2><small>{{datail.description}}</small></h2>
        <hr style="height:2px;border-width:0;color:gray;background-color:gray">
      </div>
      <div class="col-md-12 col-sm-12">
        <h3>{{datail.duration}}</h3>
        <h3>{{datail.price}}</h3>
      </div>
      
  </div>
       <div class="col-md-12 col-sm-12 vertical-spacing-25"></div>

</template>

<script>
import { ref } from "vue"

export default {

  props:['details'],
  setup(props){
      const datail = ref(props.details)
      return {datail}
      
  }
}
</script>

<style scoped>
.package-section{
  border: 1px solid rgba(0, 0, 0, 0.3);
}

.package-section::before{
  padding-top:20px ;
}

.package-image{
  width: 90vw;
}

</style>