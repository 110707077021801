<template>
    <div class="row">
    <div class="col-md-12  col-sm-12 footer-section">
        <p class="footer-legend ">&copy; Daring English - 2022</p>
    </div>
    </div>
</template>

<script>
export default {

} 
</script>

<style scoped>
.footer-section{
    background-color: var(--backg);
    color: white;
    height: 60px; 
    text-align: center;
}


.footer-legend{
    margin-top: 15px;
}

</style>