<template>
  <div class="col-md-10 col-md-offset-2 col-lg-offset-1 coach-section">
      <div class="col-md-12 vertical-spacing-75 hidden-sm"></div>
      <div class="col-ms-12 vertical-spacing-25 hidden-md hidden-lg"></div>
  <div class="row">
        <div class="col-md-3 col-md-offset-1 col-lg-offset-1 image-center coach-img" > 
          <!-- <img src="@/assets/rachael.png" alt="" > -->
        </div>
        <div class="col-md-8 col-sm-12">
          <div class="row">
            <div class="col-md-10 col-sm-12 text-style">
            <h2>Well, Hello there!</h2>
            <h3> My name is <span class="name-style"> Rachael </span>, and I am an English conversation coach. I have already helped thousands of language learners improve their English pronunciation and conversation skills. Whether your goal is to travel, increase your employability, pass a class, get a promotion or simply boost your confidence when speaking English, I can help.
            </h3>
        </div>
          </div>
        </div>
        <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
        <div class="col-sm-10 col-sm-offset-1 image-center hidden-md hidden-lg" > 
          <img src="@/assets/rachael.png" alt="" >
        </div>
        
  </div>
  <div class="col-md-12 vertical-spacing-50 hidden-sm"></div>
  <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
</div>
</template>

<script>
export default {

}
</script>

<style>

.coach-section{
  /* height: 60vh; */
  border-color: 1px solid red;
}

.image-center{
    text-align: center;
}
.name-style{
  color: #ff3ac4;
}

.coach-img{
    background-image: url('../assets/rachael.png');
    background-color: rgba(31,32,38,0.1);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center bottom;
}
</style>