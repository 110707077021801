<template>
  <Tagline />
  <Coach />
  <Enroll />
  <Mission />
  <Contact id="contact"/>
</template>

<script>
import Tagline from '@/components/Tagline.vue'
import Coach from '@/components/Coach.vue'
import Enroll from '@/components/Enroll.vue'
import Mission from '@/components/Mission.vue'
import Contact from '@/components/Contact.vue'



export default {
  components:{Tagline,Coach,Enroll,Mission,Contact}

}
</script>

<style>

</style>