<template>
  <div class="row navbar-content text-style">
      <div class="col-md-11 col-ms-12 col-md-offset-1 col-lg-offset-1">
          <div class="row">
            <div class="col-md-12 col-sm-12 vertical-spacing-25 "></div>
            <div class="col-md-6 col-sm-9 logo-image">
                <img src="@/assets/logo.png" alt="" class="">
            </div>
            <div class="col-md-5 text-right hidden-sm">
                <ul class="navbar-elements link-no-style">
                    <li><router-link to="/">Home</router-link> </li>
                    <li><router-link to="/about">Rachael</router-link> </li>
                    <li><router-link to="/sessions">Sessions</router-link> </li>
                    <li><a href="/#contact">Contact</a> </li>
            </ul>
            </div>
            <div class="col-sm-1 hidden-md hidden-lg">  
                <label for="drawer-control" class="drawer-toggle burger-menu"></label> 
                <input type="checkbox" id="drawer-control" class="drawer">
                <div class="burger-menu-bg">
                    <label for="drawer-control" class="drawer-close burger-menu-close"></label>
                    <ol class="burger-menu-links">
                        <h3> <router-link to="/">Home</router-link> </h3>
                        <h3> <router-link to="/about">Rachael</router-link> </h3>
                        <h3> <router-link to="/sessions">Sessions</router-link> </h3>
                        <h3> <a href="/#contact">Contact</a> </h3>
                    </ol>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>

.navbar-content{
    background-color:var(--backg);
    color:white;
    /* height: 90px; */
}
.navbar-elements{
    margin-top: 20px;
    text-align: right;
}

.navbar-elements li{
    display: inline;
    margin-left: 20px;
    margin-right: 15px;
    padding-top: 15px;
    padding-bottom:5px;
    cursor: pointer;
}

.navbar-elements li:hover{
    color:var(--accent);
    border-bottom: 1px solid var(--accent);
}

.logo-image img{
  color:black;
  height: 10vh;
}
.logo-image img::before{
  padding-bottom: 40px;
}

.burger-menu{
  color:var(--accent);
 font-size: 30px;
  margin-right: 0px;
  margin-bottom: 0px;
  padding: 0px;
  margin-left: 30px;
}
.burger-menu::before {
    margin-top: -12px;
}

.burger-menu-bg{
 background-color:var(--backg);
}
.burger-menu-links{
  margin-top: 8vh;
  
}
.burger-menu-links h3 a{
  color: white;
  font-family: helvetica neue,helvetica,arial,sans-serif;
  font-size: bold;
  text-decoration: none;
}

.burger-menu-close{
  background-color:var(--accent);
}


</style>