<template>
 <div class="col-md-10 col-md-offset-2 col-lg-offset-1 coach-section">
      <div class="col-md-12 vertical-spacing-25 hidden-sm"></div>
      <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
  <div class="row">
    <div class="col-md-12 col-sm-12 text-style-bold">
      <h1>What happens in our sessions?</h1>
    </div>
    <div class="col-md-12 col-sm-12 text-style-bold">
        <h3>During our first consultation, we will set your learning goals together. Sessions can include:</h3>
    </div>
    <div class="col-md-12 col-sm-12 text-style-bold">
      <ul>
        <li><h3>Spoken practice of sounds, words, or phrases</h3></li>
        <li><h3>Vocabulary building exercises</h3></li>
        <li><h3>Interview practice</h3></li>
        <li><h3>Conversational roleplay</h3></li>
        <li><h3>Free-conversation</h3></li>
        <li><h3>Discussion of academic or professional articles</h3></li>
        </ul>
    </div>
    <div class="col-md-12 col-sm-12 text-style-bold">
        <h3>All classes are private (individual).</h3>
        <h3>Detailed feedback will be given at the end of each session!</h3>
    </div>
    <div class="col-md-12 col-sm-12 text-style-bold">
      <h1>Session Types</h1>
    </div>
    <div class="col-md-4 col-sm-11 " v-for="sesion in sesiones" :key="sesion.id">
      <Package :details="sesion"/>
    </div>
    <div class="col-md-12 col-sm-12 text-style-bold">
      <h3>Contact us at Rachael.DaringEnglish@gmail.com for further details and to book a session!</h3>
    </div>
     <div class="col-md-12 col-sm-12 vertical-spacing-50"></div>
      <!-- <div class="col-md-12 vertical-spacing-25 hidden-md hidden-lg"></div> -->
  </div>
 </div>
</template>

<script>
import { ref } from "vue"
import Package from '@/components/Package.vue'

export default {

  components:{Package},
  setup(){
      const sesiones = ref([
        {id:1, 
        name:"25 minute session",
        description:"One-on-one session working intensively on pronunciation, customizable for student goals.",
        price:"$16.00 USD",
        duration:"25 min",
        img:"package_one.jpg"
        },

        {
        id:2, 
        name:"55 minute session",
        description:"One-on-one Session working on conversation, vocabulary-building, or interview skills.",
        price:"$30.00 USD",
        duration:"55 min",
        img:"package_two.jpg"
        },
      ])

      return {sesiones}
  }

}
</script>

<style>

</style>