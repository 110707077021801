<template>
  <div class="row enroll-section ">
        <div class="col-md-4 mission-img hidden-sm"> </div>
        <div class="col-md-8 col-sm-12">
            <div class="row">
                <div class="col-md-12 vertical-spacing-50 hidden-sm"></div>
                <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
                <div class="col-md-10 col-md-offset-1 col-lg-offset-1 text-style-bold">
                    <h1>About Daring English </h1>
                    <div class="text-style">
                    <h3>We teach English conversational skills, with an emphasis on pronunciation and fluency. Enjoy a relaxed, friendly environment where students can feel comfortable speaking freely and making mistakes!</h3>
                    <h3>Feel confident in your spoken English, increase your professional skill set, and watch the world open up for you! </h3>
                    </div>
                </div>
                <div class="col-md-12 vertical-spacing-50 hidden-sm"></div>
            </div>
        </div>
        <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
        <div class="col-sm-12 hidden-md hidden-lg text-center">
            <img src="@/assets/mission.jpg" alt="" class="image-small-devices" >
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.mission-img{
    background-image: url('../assets/mission.jpg');
    background-color: rgba(31,32,38,0.1);
    background-size: cover;
    background-repeat: no-repeat;
    background-position:center bottom;
}
</style>