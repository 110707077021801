<template>
    <div class="col-md-10 col-sm-12 col-md-offset-1 col-lg-offset-1 ">
          <div class="row">
            <div class="col-md-12 vertical-spacing-75 hidden-sm"></div>
            <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>
            <div class="col-md-8 col-sm-12 text-style-bold about-section">
              <h1>About Rachael</h1>
              <h3>Using a personalized and conversational approach, Rachael has worked with university students, jobseekers, and professionals to increase their English fluency and boost their conversation skills. She has a Master’s degree in Second Language Education, an internationally-recognized TESL (Teaching English as a Second Language) certification, and many years experience in the English classroom (both in Canada and in Mexico!). She loves working with students one on one to maximize learning and build a strong student/coach relationship. Let her act as your guide and personal language trainer, with private sessions customized to your needs.</h3>
              <h3>Be confident. <span class="name-style"> Dare</span> to enjoy the learning process!</h3>          
            </div>
            <div class="col-md-4 col-sm-12">
              <div>
                <img src="../assets/about_me.png" alt="">
              </div>
            </div>
          </div>
    </div>
    <div class="col-md-12 vertical-spacing-75 hidden-sm"></div>
    <div class="col-sm-12 vertical-spacing-25 hidden-md hidden-lg"></div>


</template>

<script>
export default {

}
</script>

<style scoped>
.about-section{
  background-color: #f5daf1;
}

.name-style{
  color: #ff3ac4;
}
</style>