<template>
  <div class="row tagline-content text-style-bold ">
      <div class="col-md-12 col-ms-12 text-center">
          <h2>Converse Clearly. Expand your horizons.</h2>
      </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.tagline-content{
  padding-top: 17px;
  padding-bottom: 17px;
  background-color: var(--pinkbg);
}


</style>